
import { defineComponent, computed } from 'vue'
import { useStore } from '@/utils/composables'

export default defineComponent({
	props: {
		entityType: {
			type: String,
			default: 'client',
		},
		isTooltip: {
			type: Boolean,
			default: false,
		},
		lastModifiedBy: { type: String, default: '' },
		lastModifiedDate: { type: String, default: '' },
	},
	setup(props) {
		const store = useStore()

		const formatDate = (dateString: string) => {
			if (!dateString) {
				return ''
			}
			return '- ' + new Date(dateString).toLocaleString()
		}

		const getModifiedBy = computed(() => {
			switch (props.entityType) {
				case 'payer':
					return `Last modified by ${
						store.state.payer?.payer?.lastModifiedBy
					} ${formatDate(store.state.payer?.payer?.lastModified)}`
				case 'client':
					return `Last modified by ${
						store.state.client?.client?.lastModifiedBy
					} ${formatDate(store.state.client?.client?.lastModified)}`
				case 'tenant':
					return `Last modified by ${
						store.state.tenant?.tenant?.lastModifiedBy
					} ${formatDate(store.state.tenant?.tenant?.lastModified)}`
				case 'user':
					return `Last modified by ${props.lastModifiedBy} ${formatDate(
						props.lastModifiedDate
					)}`
				default:
					return ''
			}
		})

		return {
			getModifiedBy,
		}
	},
})
