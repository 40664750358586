
import {
	defineComponent,
	reactive,
	toRefs,
	SetupContext,
	watchEffect,
} from 'vue'

export default defineComponent({
	name: 'myndshft-toggle',
	props: {
		id: String,
		label: String,
		value: Boolean,
		isDisabled: Boolean,
		isReadOnly: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, context: SetupContext) {
		const state = reactive({
			isActive: props.value as boolean,
			disabled: props.isDisabled,
			readonly: props.isReadOnly,
		})

		watchEffect(() => {
			state.isActive = props.value
			state.disabled = props.isDisabled
		})

		const onToggle = () => {
			if (!state.disabled && !state.readonly) {
				state.isActive = !state.isActive
				context.emit('input', state.isActive)
			}
		}

		return {
			onToggle,
			...toRefs(state),
		}
	},
})
